/** @format */
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProductGroupState } from "../../store/dashboardSlice";
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	FormControl,
	Autocomplete,
	TextField,
	Grid,
	Typography,
	CircularProgress,
	Button,
	FormControlLabel,
	Switch,
} from "@mui/material";
import {
	useGetGroupsQuery,
	useGetProductsForDashboardQuery,
	useUploadProductImagesMutation,
	useGetProductImagesQuery,
} from "../../api/mainApi";
import GroupDocumentsForm from "./GroupDocumentsForm";
import ProductImagePreview from "./ProductImagePreview";
import ProductsList from "./ProductsList";

const ITEM_HEIGHT = 35;
const ITEMS_TO_SHOW = 20;

const ProductGroupManagement = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const [fileObjects, setFileObjects] = useState({});
	const {
		selectedGroup,
		selectedImages,
		selectedImageIndex,
		assignedImages,
		stableProducts,
		enableCropping = false,
	} = useSelector((state) => state.dashboard.productGroupTab);

	const [uploadProductImages] = useUploadProductImagesMutation();

	const { data: groups, isLoading: groupsLoading, error: groupsError } = useGetGroupsQuery();

	const {
		data: products,
		isLoading: productsLoading,
		error: productsError,
	} = useGetProductsForDashboardQuery(selectedGroup?.id, {
		skip: !selectedGroup,
	});

	// Update stableProducts only when products query completes
	useEffect(() => {
		if (!productsLoading && products) {
			dispatch(updateProductGroupState({ stableProducts: products }));
		} else if (!products) {
			dispatch(updateProductGroupState({ stableProducts: null }));
		}
	}, [products, productsLoading, dispatch]);

	const { data: productImages, refetch: refetchProductImages } = useGetProductImagesQuery(
		selectedGroup?.id && stableProducts
			? {
					groupId: selectedGroup.id,
					productIds: stableProducts
						.map((p) => p.ID_broj)
						.filter(Boolean)
						.join(","),
			  }
			: null,
		{ skip: !selectedGroup || !stableProducts },
	);

	const handleGroupSelect = (_event, newValue) => {
		dispatch(
			updateProductGroupState({
				selectedGroup: newValue,
				stableProducts: null,
			}),
		);
	};

	const handleImagesSelected = (newImages) => {
		dispatch(
			updateProductGroupState({
				selectedImages: Array.isArray(newImages) ? newImages : [newImages],
			}),
		);
	};

	const handleImageRemove = (indexToRemove) => {
		const newImages = selectedImages.filter((_, index) => index !== indexToRemove);
		URL.revokeObjectURL(selectedImages[indexToRemove].preview);

		dispatch(
			updateProductGroupState({
				selectedImages: newImages,
				selectedImageIndex: selectedImageIndex === indexToRemove ? null : selectedImageIndex,
			}),
		);
	};

	const handleImageClick = (index) => {
		dispatch(
			updateProductGroupState({
				selectedImageIndex: index === selectedImageIndex ? null : index,
			}),
		);
	};

	const handleProductClick = async (productId, remove = false) => {
		if (remove) {
			const newAssignments = { ...assignedImages };
			delete newAssignments[productId];
			dispatch(updateProductGroupState({ assignedImages: newAssignments }));
			return;
		}

		if (selectedImageIndex === null) return;

		try {
			dispatch(
				updateProductGroupState({
					assignedImages: {
						...assignedImages,
						[productId]: selectedImages[selectedImageIndex],
					},
					selectedImageIndex: null,
				}),
			);
		} catch (error) {
			console.error("Failed to assign image:", error);
		}
	};

	const handleSubmitAssignments = async () => {
		try {
			const formData = new FormData();

			Object.entries(assignedImages).forEach(([productId, image]) => {
				const fileIndex = selectedImages.findIndex((img) => img.preview === image.preview);
				if (fileIndex !== -1 && fileObjects[fileIndex]) {
					formData.append("images[]", fileObjects[fileIndex].file);
					formData.append("productIds[]", productId);
				}
			});

			await uploadProductImages(formData).unwrap();
			await refetchProductImages();
			dispatch(updateProductGroupState({ assignedImages: {} }));
		} catch (error) {
			console.error("Failed to submit assignments:", error);
		}
	};

	if (groupsLoading || groupsError) {
		return (
			<Box
				sx={{
					p: 3,
					height: "84vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{groupsLoading ? (
					<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
						<CircularProgress
							size={24}
							sx={{ color: "orange" }}
						/>
						<Typography>Loading groups...</Typography>
					</Box>
				) : (
					<Typography color="error">Error loading groups: {groupsError.message}</Typography>
				)}
			</Box>
		);
	}

	return (
		<Box sx={{ p: 3, height: "84vh" }}>
			<Grid
				container
				spacing={3}
			>
				{/* Left side - Group Selector and Documents */}
				<Grid
					item
					xs={6}
					sx={{ height: "fit-content" }}
				>
					{/* Group Selector Card */}
					<Card sx={{ mb: 2 }}>
						<CardHeader title={translate("groups.select")} />
						<CardContent>
							<FormControl fullWidth>
								<Autocomplete
									value={selectedGroup}
									onChange={handleGroupSelect}
									options={groups || []}
									getOptionLabel={(option) => `${option.name} (${option.id})`}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => (
										<TextField
											{...params}
											label={translate("groups.selectPlaceholder")}
											size="small"
										/>
									)}
									ListboxProps={{ style: { maxHeight: ITEM_HEIGHT * ITEMS_TO_SHOW } }}
									sx={{ "& .MuiAutocomplete-option": { height: ITEM_HEIGHT, py: 0 } }}
									renderOption={(props, option) => (
										<li
											{...props}
											key={option.id}
										>
											{option.name} ({option.id})
										</li>
									)}
								/>
							</FormControl>
						</CardContent>
					</Card>

					{selectedGroup && (
						<Box sx={{ mb: 2 }}>
							<GroupDocumentsForm
								selectedGroupId={selectedGroup.id}
								selectedGroupName={selectedGroup.name}
							/>
						</Box>
					)}
				</Grid>

				{/* Right side - Products and Images */}
				<Grid
					container
					item
					xs={6}
					spacing={2}
				>
					{/* Products List */}
					<Grid
						item
						xs={6}
						sx={{ height: "fit-content" }}
					>
						<Card sx={{ height: "100%" }}>
							<CardHeader title={translate("products.list")} />
							<CardContent sx={{ p: 0 }}>
								<Box sx={{ px: 2, mb: 0 }}>
									<Button
										fullWidth
										variant="contained"
										onClick={handleSubmitAssignments}
										sx={{
											bgcolor: "#F1801E",
											"&:hover": { bgcolor: "#A85915" },
											visibility: Object.keys(assignedImages).length > 0 ? "visible" : "hidden",
											height: 36,
											minHeight: 36,
										}}
									>
										{translate("products.saveChanges")}
									</Button>

									{products?.length ? (
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ mt: 2 }}
										>
											{translate("products.count", { count: products.length })}
										</Typography>
									) : null}
								</Box>

								<Box>
									{selectedGroup ? (
										<ProductsList
											products={products}
											isLoading={productsLoading}
											error={productsError}
											onProductClick={handleProductClick}
											selectedImageIndex={selectedImageIndex}
											assignedImages={assignedImages}
											existingImages={productImages}
										/>
									) : (
										<Typography
											color="text.secondary"
											sx={{ p: 2 }}
										>
											{translate("groups.noSelection")}
										</Typography>
									)}
								</Box>
							</CardContent>
						</Card>
					</Grid>

					{/* Images Preview Area */}
					<Grid
						item
						xs={6}
					>
						<Card>
							<CardHeader
								title={translate("images.title")}
								action={
									<FormControlLabel
										control={
											<Switch
												checked={enableCropping}
												onChange={(e) =>
													dispatch(updateProductGroupState({ enableCropping: e.target.checked }))
												}
												sx={{
													"& .MuiSwitch-switchBase.Mui-checked": { color: "#f1801e" },
													"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
														backgroundColor: "#f1801e",
													},
												}}
											/>
										}
										label={translate("images.enableCropping")}
										sx={{ mr: 1 }}
									/>
								}
							/>
							<CardContent
								sx={{ height: "calc(100% - 150px)", overflow: "auto", p: 0, minHeight: "115px" }}
							>
								<ProductImagePreview
									selectedImages={selectedImages}
									onImagesSelected={handleImagesSelected}
									onImageRemove={handleImageRemove}
									onImageClick={handleImageClick}
									selectedImageIndex={selectedImageIndex}
									enableCropping={enableCropping}
									onFileObjectsChange={setFileObjects}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ProductGroupManagement;
