/** @format */

import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	IconButton,
} from "@mui/material";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { Close as CloseIcon } from "@mui/icons-material";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	if (!aspect) {
		return {
			unit: "%",
			width: 90,
			height: 90,
			x: 5,
			y: 5,
		};
	}
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

const ImageCropModal = ({ open, onClose, image, onCropComplete }) => {
	const [crop, setCrop] = useState();
	const [imgRef, setImgRef] = useState(null);
	const [aspectRatio, setAspectRatio] = useState(undefined);

	const aspectRatios = {
		Free: undefined,
		"1:1": 1,
		"4:3": 4 / 3,
		"16:9": 16 / 9,
	};

	const onImageLoad = (e) => {
		const { width, height } = e.currentTarget;
		const crop = centerAspectCrop(width, height, aspectRatio);
		setCrop(crop);
		setImgRef(e.currentTarget);
	};

	const handleAspectRatioChange = (ratio) => {
		if (!imgRef) return;
		const { width, height } = imgRef;
		setAspectRatio(ratio);
		const newCrop = centerAspectCrop(width, height, ratio);
		setCrop(newCrop);
	};

	const handleComplete = () => {
		if (imgRef && crop) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			const scaleX = imgRef.naturalWidth / imgRef.width;
			const scaleY = imgRef.naturalHeight / imgRef.height;

			canvas.width = crop.width;
			canvas.height = crop.height;

			ctx.drawImage(
				imgRef,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height,
			);

			canvas.toBlob(
				(blob) => {
					const croppedFile = new File([blob], image.name, {
						type: "image/jpeg",
						lastModified: Date.now(),
					});
					onCropComplete({
						...image,
						file: croppedFile,
						preview: URL.createObjectURL(blob),
					});
				},
				"image/jpeg",
				0.95,
			);
		}
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="md"
			fullWidth
			PaperProps={{
				sx: {
					bgcolor: "#0D0D0D",
					color: "white",
					backgroundImage: "none",
				},
			}}
		>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #333",
				}}
			>
				<Typography>Edit Image</Typography>
				<IconButton
					onClick={onClose}
					sx={{ color: "white" }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					pb: 1.25,
					maxHeight: "calc(100vh - 200px)",
					overflow: "hidden",
					"& .ReactCrop": {
						maxHeight: "70vh",
					},
					"& .ReactCrop__crop-selection": {
						borderWidth: "2px",
						borderColor: "#f1801e",
					},
					"& .ReactCrop__child-wrapper": {
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					},
				}}
			>
				<div style={{ padding: "1rem 0" }}>
					{Object.entries(aspectRatios).map(([name, ratio]) => (
						<Button
							key={name}
							onClick={() => handleAspectRatioChange(ratio)}
							variant={aspectRatio === ratio ? "contained" : "outlined"}
							sx={{
								mr: 1,
								color: "white",
								borderColor: "#333",
								"&.Mui-selected": {
									bgcolor: "#f1801e",
								},
							}}
						>
							{name}
						</Button>
					))}
				</div>
				<ReactCrop
					crop={crop}
					onChange={(c) => setCrop(c)}
					aspect={aspectRatio}
				>
					<img
						src={image.preview}
						alt="Crop preview"
						onLoad={onImageLoad}
					/>
				</ReactCrop>
			</DialogContent>
			<DialogActions
				sx={{
					borderTop: "1px solid #333",
					px: 3,
					py: 2,
				}}
			>
				<Button
					onClick={onClose}
					sx={{
						color: "white",
						"&:hover": {
							bgcolor: "rgba(255,255,255,0.1)",
						},
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={handleComplete}
					variant="contained"
					sx={{
						bgcolor: "#f1801e",
						"&:hover": {
							bgcolor: "#d16918",
						},
					}}
				>
					Crop & Apply
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageCropModal;
