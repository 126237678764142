/** @format */
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
	Card,
	CardContent,
	CardHeader,
	Button,
	Typography,
	Box,
	Paper,
	IconButton,
	Grid,
	Divider,
} from "@mui/material";
import {
	Delete as DeleteIcon,
	UploadFile as UploadFileIcon,
	InsertDriveFile as FileIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
	useGetGroupDocumentsQuery,
	useUploadGroupDocumentsMutation,
	useDeleteGroupDocumentMutation,
} from "../../api/mainApi";

const StyledUploadLabel = styled("label")(({ theme }) => ({
	display: "block",
	padding: "10px",
	border: "1px solid #F1801E",
	borderRadius: "8px",
	backgroundColor: "black",
	color: "grey",
	cursor: "pointer",
	"&:hover": {
		borderColor: "#A85915",
	},
}));

const GroupDocumentsForm = ({ selectedGroupId, selectedGroupName }) => {
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	const { t: translate } = useTranslation();

	const { data: existingDocuments = [], isLoading } = useGetGroupDocumentsQuery(selectedGroupId, {
		skip: !selectedGroupId,
	});

	const [uploadFiles] = useUploadGroupDocumentsMutation();
	const [deleteFile] = useDeleteGroupDocumentMutation();

	const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB max file size
	const ALLOWED_DOCUMENT_TYPES = [
		"application/pdf",
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	];

	const handleDocumentSelect = (event) => {
		const files = Array.from(event.target.files);

		// Validate files
		const invalidFiles = files.filter((file) => {
			if (file.size > MAX_FILE_SIZE) {
				setErrorMessage(`File ${file.name} is too large. Maximum size is 10MB.`);
				return true;
			}
			if (!ALLOWED_DOCUMENT_TYPES.includes(file.type)) {
				setErrorMessage(
					`File ${file.name} is not supported. Only PDF and DOC/DOCX files are allowed.`,
				);
				return true;
			}
			return false;
		});

		if (invalidFiles.length > 0) {
			return;
		}

		setSelectedDocuments((prevFiles) => [...prevFiles, ...files]);
		setErrorMessage("");
		event.target.value = null;
	};

	const removeSelectedDocument = (indexToRemove) => {
		setSelectedDocuments((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
	};

	const handleSubmit = async () => {
		if (!selectedGroupId) {
			setErrorMessage("Please select a group first");
			return;
		}

		const formData = new FormData();
		formData.append("groupId", selectedGroupId);

		selectedDocuments.forEach((file) => {
			formData.append("documents[]", file);
		});

		try {
			await uploadFiles(formData).unwrap();
			setSelectedDocuments([]);
			setErrorMessage("");
		} catch (error) {
			setErrorMessage("Error uploading files. Please try again.");
		}
	};

	const handleDeleteDocument = async (fileName) => {
		try {
			await deleteFile({
				groupId: selectedGroupId,
				fileName: fileName,
			}).unwrap();
		} catch (error) {
			setErrorMessage("Error deleting file. Please try again.");
		}
	};

	return (
		<Card>
			<CardHeader
				title={
					selectedGroupName
						? translate("documents.titleWithName", { groupName: selectedGroupName })
						: translate("documents.title")
				}
			/>
			<CardContent>
				<Grid
					container
					spacing={3}
				>
					{/* Existing Files Section */}
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h6"
							gutterBottom
						>
							{translate("documents.existing")}
						</Typography>
						{isLoading ? (
							<Typography>Loading...</Typography>
						) : existingDocuments.length > 0 ? (
							<Box sx={{ mb: 3 }}>
								{existingDocuments.map((doc, index) => (
									<Paper
										key={index}
										sx={{
											p: 1,
											mb: 1,
											display: "flex",
											alignItems: "center",
											gap: 1,
										}}
									>
										<FileIcon />
										<Typography sx={{ flexGrow: 1 }}>{doc.name}</Typography>
										<IconButton
											size="small"
											onClick={() => handleDeleteDocument(doc.name)}
											color="error"
										>
											<DeleteIcon />
										</IconButton>
									</Paper>
								))}
							</Box>
						) : (
							<Typography color="text.secondary">{translate("documents.noFiles")}</Typography>
						)}
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Divider sx={{ my: 2 }} />
					</Grid>

					{/* Upload New Documents Section */}
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h6"
							gutterBottom
						>
							{translate("documents.addDocuments")}
						</Typography>

						<input
							type="file"
							accept=".pdf,.doc,.docx"
							onChange={handleDocumentSelect}
							style={{ display: "none" }}
							id="document-upload"
							multiple
						/>
						<StyledUploadLabel htmlFor="document-upload">
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
								<UploadFileIcon />
								<Typography>{translate("documents.select")}</Typography>
							</Box>
						</StyledUploadLabel>

						{errorMessage && (
							<Typography
								color="error"
								sx={{ mt: 1 }}
							>
								{errorMessage}
							</Typography>
						)}

						{selectedDocuments.length > 0 && (
							<Box sx={{ mt: 2 }}>
								<Typography
									variant="subtitle1"
									sx={{ mb: 1 }}
								>
									Selected Files:
								</Typography>
								{selectedDocuments.map((file, index) => (
									<Paper
										key={index}
										sx={{
											p: 1,
											mb: 1,
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Typography
											noWrap
											sx={{ maxWidth: "80%" }}
										>
											{file.name}
										</Typography>
										<IconButton
											size="small"
											onClick={() => removeSelectedDocument(index)}
										>
											<DeleteIcon />
										</IconButton>
									</Paper>
								))}

								<Button
									variant="contained"
									onClick={handleSubmit}
									disabled={selectedDocuments.length === 0}
									sx={{ mt: 2 }}
								>
									{translate("documents.upload")}
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default GroupDocumentsForm;
