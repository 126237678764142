/** @format */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateUserManagementState } from "../../store/dashboardSlice";
import {
	Select,
	MenuItem,
	TextField,
	InputLabel,
	FormControl,
	Card,
	CardContent,
	CardHeader,
	Button,
	IconButton,
	Typography,
	Grid,
	Box,
	Divider,
	InputAdornment,
	Paper,
	FormControlLabel,
	Switch,
} from "@mui/material";
import { Visibility, VisibilityOff, Link as LinkIcon } from "@mui/icons-material";

// Mock database data
const mockUsers = [
	{
		buyerId: "B001",
		companyName: "Tech Solutions Inc",
		address: "123 Tech Street, Silicon Valley, CA",
		email: "contact@techsolutions.com",
	},
	{
		buyerId: "B002",
		companyName: "Global Innovations Ltd",
		address: "456 Innovation Ave, New York, NY",
		email: "info@globalinnovations.com",
	},
	{
		buyerId: "B003",
		companyName: "Future Enterprises",
		address: "789 Future Blvd, Seattle, WA",
		email: "contact@futureenterprises.com",
	},
	{
		buyerId: "B004",
		companyName: "Infinite Horizons",
		address: "101 Horizon Road, Los Angeles, CA",
		email: "contact@infinitehorizons.com",
	},
	{
		buyerId: "B005",
		companyName: "Digital Solutions Ltd",
		address: "321 Digital Drive, San Francisco, CA",
		email: "mitar@digitalsolutions.com",
	},
];

// Mock admins data
const mockAdmins = [
	{
		adminId: "A001",
		firstName: "Janko",
		lastName: "Jankovic",
		email: "janko@centralh.com",
		role: "admin",
	},
	{
		adminId: "A002",
		firstName: "Jelena",
		lastName: "Markovic",
		email: "jelena@centralh.com",
		role: "superadmin",
	},
	{
		adminId: "A003",
		firstName: "Alisa",
		lastName: "Johnson",
		email: "alisa@centralh.com",
		role: "admin",
	},
	{
		adminId: "A004",
		firstName: "Boban",
		lastName: "Brown",
		email: "boban@centralh.com",
		role: "admin",
	},
];

const UserManagementSystem = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		showPassword,
		selectedUser,
		selectedAdmin,
		formMode,
		userFormData,
		adminFormData,
		connections,
	} = useSelector((state) => state.dashboard.userManagementTab);

	const [filterByAdmin, setFilterByAdmin] = useState(false);

	const handleUserSelect = (event) => {
		const buyerId = event.target.value;
		const user = mockUsers.find((u) => u.buyerId === buyerId);
		if (user) {
			dispatch(
				updateUserManagementState({
					selectedUser: user,
					userFormData: {
						...userFormData,
						companyName: user.companyName,
						buyerId: user.buyerId,
						address: user.address,
						email: user.email,
					},
				}),
			);
		}
	};

	const handleInputChange = (formType, field) => (event) => {
		const value = event.target.value;
		dispatch(
			updateUserManagementState({
				[formType === "user" ? "userFormData" : "adminFormData"]: {
					...(formType === "user" ? userFormData : adminFormData),
					[field]: value,
				},
			}),
		);
	};

	const handleConnection = () => {
		if (selectedUser && selectedAdmin) {
			const newConnection = {
				adminId: selectedAdmin.adminId,
				userId: selectedUser.buyerId,
			};

			const connectionExists = connections.some(
				(conn) => conn.adminId === newConnection.adminId && conn.userId === newConnection.userId,
			);

			if (connectionExists) {
				alert("This connection already exists!");
				return;
			}

			dispatch(
				updateUserManagementState({
					connections: [...connections, newConnection],
				}),
			);
		}
	};

	const renderUserForm = (formType, data) => {
		const isAdminForm = formType === "admin";

		return (
			<Card>
				<CardHeader
					title={
						isAdminForm ? t("userManagement.adminSelect.title") : t("userManagement.details.title")
					}
				/>
				<CardContent>
					<Box
						component="form"
						sx={{ "& .MuiTextField-root": { mb: 2 } }}
					>
						<Grid
							container
							spacing={2}
						>
							{/* Company Information */}
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t("userManagement.details.companyInfo.companyName")}
									value={isAdminForm ? "Central H" : data.companyName}
									InputProps={{ readOnly: true }}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t(
										`userManagement.details.companyInfo.${isAdminForm ? "adminId" : "buyerId"}`,
									)}
									value={data.buyerId}
									onChange={handleInputChange(formType, "buyerId")}
									InputProps={{ readOnly: !isAdminForm }}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label={t("userManagement.details.companyInfo.address")}
									value={data.address}
									onChange={handleInputChange(formType, "address")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									label={t("userManagement.details.companyInfo.email")}
									value={data.email}
									onChange={handleInputChange(formType, "email")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Divider sx={{ my: 2 }}>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										{/* Login Credentials */}
										{t("userManagement.details.loginCredentials.title")}
									</Typography>
								</Divider>
							</Grid>

							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t("userManagement.details.loginCredentials.username")}
									value={data.username}
									onChange={handleInputChange(formType, "username")}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									type={showPassword ? "text" : "password"}
									label={t("userManagement.details.loginCredentials.password")}
									value={data.password}
									onChange={handleInputChange(formType, "password")}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() =>
														dispatch(updateUserManagementState({ showPassword: !showPassword }))
													}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Divider sx={{ my: 2 }}>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										{/* Personal Information */}
										{t("userManagement.details.personalInfo.title")}
									</Typography>
								</Divider>
							</Grid>

							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t("userManagement.details.personalInfo.firstName")}
									value={data.firstName}
									onChange={handleInputChange(formType, "firstName")}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t("userManagement.details.personalInfo.lastName")}
									value={data.lastName}
									onChange={handleInputChange(formType, "lastName")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<FormControl fullWidth>
									<InputLabel>{t("userManagement.details.personalInfo.role")}</InputLabel>
									<Select
										value={data.role}
										onChange={handleInputChange(formType, "role")}
										label="Role"
										disabled={!isAdminForm}
										size="small"
									>
										{isAdminForm ? (
											[
												<MenuItem
													key="admin"
													value="admin"
												>
													{t("userManagement.roles.admin")}
												</MenuItem>,
												<MenuItem
													key="superadmin"
													value="superadmin"
												>
													{t("userManagement.roles.superadmin")}
												</MenuItem>,
											]
										) : (
											<MenuItem value="buyer">{t("userManagement.roles.buyer")}</MenuItem>
										)}
									</Select>
								</FormControl>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
									<Button
										variant="outlined"
										onClick={() => {
											dispatch(
												updateUserManagementState(
													formType === "admin"
														? {
																adminFormData: {
																	firstName: "",
																	lastName: "",
																	role: "admin",
																	companyName: "Central H",
																	buyerId: "",
																	address: "",
																	username: "",
																	password: "",
																	email: "",
																},
														  }
														: {
																userFormData: {
																	...userFormData,
																	firstName: "",
																	lastName: "",
																	username: "",
																	password: "",
																},
														  },
												),
											);
										}}
									>
										{/* Clear */}
										{t("userManagement.actions.clear")}
									</Button>
									<Button
										variant="contained"
										onClick={() => {
											const formToSave = formType === "admin" ? adminFormData : userFormData;
											console.log(`Saving ${formType} data:`, formToSave);
											alert(
												`${
													formType.charAt(0).toUpperCase() + formType.slice(1)
												} data saved successfully!`,
											);
										}}
									>
										{t(`userManagement.actions.save${isAdminForm ? "Admin" : "User"}`)}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		);
	};

	const renderConnectionsPanel = () => (
		<Card sx={{ mt: 2 }}>
			<CardHeader
				title={t("userManagement.connections.title")}
				action={
					<FormControlLabel
						control={
							<Switch
								checked={filterByAdmin}
								onChange={(e) => setFilterByAdmin(e.target.checked)}
								sx={{
									"& .MuiSwitch-switchBase.Mui-checked": { color: "#f1801e" },
									"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
										backgroundColor: "#f1801e",
									},
								}}
							/>
						}
						label={t("userManagement.connections.filterByAdmin")}
					/>
				}
			/>
			<CardContent>
				<Box sx={{ "& > *": { mb: 2 } }}>
					{connections
						.filter((conn) =>
							filterByAdmin
								? selectedAdmin && conn.adminId === selectedAdmin.adminId
								: selectedUser && conn.userId === selectedUser.buyerId,
						)
						.map((connection, index) => (
							<Paper
								key={index}
								variant="outlined"
								sx={{
									p: 2,
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Typography
									dangerouslySetInnerHTML={{
										__html: t("userManagement.connections.connectionFormat", {
											userId: connection.userId,
											companyName: mockUsers.find((u) => u.buyerId === connection.userId)
												?.companyName,
											adminId: connection.adminId,
											adminName:
												mockAdmins.find((a) => a.adminId === connection.adminId)?.firstName +
												" " +
												mockAdmins.find((a) => a.adminId === connection.adminId)?.lastName,
										}),
									}}
								/>
								<Button
									variant="text"
									size="small"
									onClick={() => {
										dispatch(
											updateUserManagementState({
												connections: connections.filter((_, i) => i !== index),
											}),
										);
									}}
								>
									{t("userManagement.connections.remove")}
								</Button>
							</Paper>
						))}
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="outlined"
							startIcon={<LinkIcon />}
							onClick={handleConnection}
							disabled={!selectedUser || !selectedAdmin || formMode === "admin"}
						>
							{t("userManagement.connections.connect")}
						</Button>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);

	return (
		<Box sx={{ p: 3, height: "84vh" }}>
			{/* Mode Selection */}
			<Box sx={{ mb: 3, display: "flex", gap: 2 }}>
				<Button
					variant={formMode === "user" ? "contained" : "outlined"}
					onClick={() => dispatch(updateUserManagementState({ formMode: "user" }))}
				>
					{/* Manage Users */}
					{t("userManagement.tabs.users")}
				</Button>
				<Button
					variant={formMode === "admin" ? "contained" : "outlined"}
					onClick={() => dispatch(updateUserManagementState({ formMode: "admin" }))}
				>
					{/* Manage Admins */}
					{t("userManagement.tabs.admins")}
				</Button>
			</Box>

			<Grid
				container
				spacing={3}
			>
				{/* Left side - Forms */}
				<Grid
					item
					xs={6}
				>
					{formMode === "user" ? (
						<>
							<Card sx={{ mb: 2 }}>
								<CardHeader title={t("userManagement.userSelect.title")} />
								<CardContent>
									<FormControl
										fullWidth
										sx={{ mb: 2 }}
									>
										<InputLabel
											size="small"
											sx={{
												backgroundColor: "black",
												px: 1,
											}}
										>
											{t("userManagement.userSelect.placeholder")}
										</InputLabel>
										<Select
											value={selectedUser?.buyerId || ""}
											onChange={handleUserSelect}
											label="TODO: Write a label"
											size="small"
										>
											{mockUsers.map((user) => (
												<MenuItem
													key={user.buyerId}
													value={user.buyerId}
												>
													{user.companyName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</CardContent>
							</Card>
							{renderUserForm("user", userFormData)}
						</>
					) : (
						renderUserForm("admin", adminFormData)
					)}
				</Grid>

				{/* Right side - Connections */}
				<Grid
					item
					xs={6}
				>
					<Card>
						<CardHeader title={t("userManagement.adminSelect.title")} />
						<CardContent>
							<FormControl
								fullWidth
								sx={{ mb: 2 }}
							>
								<InputLabel
									size="small"
									sx={{
										backgroundColor: "black",
										px: 1,
									}}
								>
									{t("userManagement.adminSelect.placeholder")}
								</InputLabel>
								<Select
									value={selectedAdmin?.adminId || ""}
									onChange={(event) => {
										const admin = mockAdmins.find((a) => a.adminId === event.target.value);
										dispatch(updateUserManagementState({ selectedAdmin: admin }));
									}}
									label={t("userManagement.adminSelect.placeholder")}
									size="small"
								>
									{mockAdmins.map((admin) => (
										<MenuItem
											key={admin.adminId}
											value={admin.adminId}
										>
											{`${admin.firstName} ${admin.lastName} (${admin.role})`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</CardContent>
					</Card>

					{renderConnectionsPanel()}
				</Grid>
			</Grid>
		</Box>
	);
};

export default UserManagementSystem;
