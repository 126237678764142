import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import translationSR from './locales/sr.json';
import translationEN from './locales/en.json';

// Set language here - just change 'sr' to 'en' to switch languages
const LANGUAGE = 'sr';  // or 'en' for English

const resources = {
	sr: {
		translation: translationSR
	},
	en: {
		translation: translationEN
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: LANGUAGE, // default language
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;