import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	productGroupTab: {
		selectedGroup: null,
		selectedImages: [], // Will now only contain {name, preview}
		selectedImageIndex: null,
		assignedImages: {}, // Will now only contain {name, preview}
		stableProducts: null,
		enableCropping: false,
	},
	userManagementTab: {
		selectedUser: null,
		selectedAdmin: null,
		connections: [],
		formMode: "user",
		userFormData: {
			firstName: "",
			lastName: "",
			role: "buyer",
			companyName: "",
			buyerId: "",
			address: "",
			username: "",
			password: "",
			email: "",
		},
		adminFormData: {
			firstName: "",
			lastName: "",
			role: "admin",
			companyName: "Central H",
			buyerId: "",
			address: "",
			username: "",
			password: "",
			email: "",
		}
	}
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		updateProductGroupState: (state, action) => {
			state.productGroupTab = { ...state.productGroupTab, ...action.payload };
		},
		updateUserManagementState: (state, action) => {
			state.userManagementTab = { ...state.userManagementTab, ...action.payload };
		},
		resetDashboardState: () => initialState
	}
});

export const {
	updateProductGroupState,
	updateUserManagementState,
	resetDashboardState
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
