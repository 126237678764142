/** @format */
import { useTranslation } from "react-i18next";
import React from "react";
import {
	Box,
	Typography,
	CircularProgress,
	Card,
	CardMedia,
	CardContent,
	IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
// * Note: Deliberately commented out delete functionality | Do not remove this!
// import { useDeleteProductImageMutation } from "../../api/mainApi";

const ProductsList = ({
	products,
	isLoading,
	error,
	onProductClick,
	selectedImageIndex,
	assignedImages,
	existingImages,
}) => {
	const { t: translate } = useTranslation();

	// * Note: Deliberately commented out delete functionality | Do not remove this!
	// const [deleteProductImage] = useDeleteProductImageMutation();

	// const handleDeleteExistingImage = async (e, productId, fileName) => {
	// 	e.stopPropagation();
	// 	try {
	// 		await deleteProductImage({
	// 			productId,
	// 			fileName,
	// 		}).unwrap();
	// 	} catch (error) {
	// 		console.error("Failed to delete image:", error);
	// 		// TODO: Add error notification
	// 	}
	// };

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "80%",
					// minHeight: "400px",
				}}
			>
				<CircularProgress
					size={24}
					sx={{ color: "#F1801E" }}
				/>
			</Box>
		);
	}

	if (error) {
		return (
			<Typography
				color="error"
				sx={{ p: 2 }}
			>
				Error loading products: {error.message}
			</Typography>
		);
	}

	if (!products?.length) {
		return (
			<Typography
				color="text.secondary"
				sx={{ p: 2 }}
			>
				No products found in this group
			</Typography>
		);
	}

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: "1fr",
				gap: 2,
				p: 2,
				maxHeight: "calc(100vh - 290px)",
				overflow: "auto",
			}}
		>
			{products.map((product) => {
				const assignedImage = assignedImages[product.ID_broj];
				const existingImage = (existingImages || []).find((img) =>
					img?.path?.includes(`/products_photos/${product.ID_broj}/`),
				);

				return (
					<Card
						key={product.ID_broj}
						onClick={() => selectedImageIndex !== null && onProductClick(product.ID_broj)}
						sx={{
							height: 220,
							display: "flex",
							flexDirection: "column",
							cursor: selectedImageIndex !== null ? "pointer" : "default",
							transition: "transform 0.2s ease",
							"&:hover": {
								boxShadow: selectedImageIndex !== null ? 6 : "none",
								transform: selectedImageIndex !== null ? "scale(0.99)" : "none",
							},
							outline: selectedImageIndex !== null ? "2px dashed #F1801E" : "none",
							outlineOffset: "2px",
						}}
					>
						<CardMedia
							component="div"
							sx={{
								height: 140,
								backgroundColor: "grey.800",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								position: "relative",
								"&:hover": {
									backgroundColor: selectedImageIndex !== null ? "#575453" : "none",
								},
							}}
						>
							{assignedImage ? (
								<>
									<img
										src={assignedImage.preview}
										alt={product.Naziv}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											border: "1px solid red",
											boxSizing: "border-box",
											borderRadius: "5px",
										}}
									/>
									<Box
										sx={{
											position: "absolute",
											bottom: 0,
											left: 0,
											right: 0,
											bgcolor: "rgba(0,0,0,0.7)",
											p: 1,
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Typography
											variant="caption"
											sx={{
												color: "white",
												maxWidth: "70%",
												overflow: "hidden",
												textOverflow: "ellipsis",
												whiteSpace: "nowrap",
											}}
										>
											{translate("images.notSaved")}
										</Typography>
										<IconButton
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onProductClick(product.ID_broj, true);
											}}
											sx={{ color: "white" }}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</Box>
								</>
							) : existingImage ? (
								<>
									<img
										src={`https://backend-b2b.central-ch.com${existingImage.path}`}
										alt={product.Naziv}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
										}}
									/>
									{/* Note: Deliberately commented out delete functionality | Do not remove this! */}
									{/* <Box
										sx={{
											position: "absolute",
											bottom: 0,
											left: 0,
											right: 0,
											bgcolor: "rgba(0,0,0,0.7)",
											p: 1,
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Typography
											variant="caption"
											sx={{
												color: "white",
												maxWidth: "70%",
												overflow: "hidden",
												textOverflow: "ellipsis",
												whiteSpace: "nowrap",
											}}
										>
											{existingImage.name}
										</Typography>
										<IconButton
											size="small"
											onClick={(e) =>
												handleDeleteExistingImage(e, product.ID_broj, existingImage.name)
											}
											sx={{ color: "white" }}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</Box> */}
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: 1,
										p: 2,
										textAlign: "center",
									}}
								>
									{selectedImageIndex !== null ? (
										<Typography
											color="#F1801E"
											variant="body2"
										>
											{translate("images.clickToAssign")}
										</Typography>
									) : (
										<Typography
											color="text.secondary"
											variant="body2"
										>
											{translate("images.noImage")}
										</Typography>
									)}
								</Box>
							)}
						</CardMedia>
						<CardContent>
							<Typography
								variant="body2"
								component="div"
								sx={{
									fontSize: "0.875rem",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
								title={product.Naziv}
							>
								{product.Naziv}
							</Typography>
							<Typography
								variant="caption"
								color="text.secondary"
							>
								ID: {product.ID_broj}
							</Typography>
						</CardContent>
					</Card>
				);
			})}
		</Box>
	);
};

export default ProductsList;
