/** @format */

import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { Box, Typography, Paper, IconButton, Button } from "@mui/material";
import { Delete as DeleteIcon, Image as ImageIcon } from "@mui/icons-material";
import Compressor from "compressorjs";
import ImageCropModal from "./ImageCropModal";

const ProductImagePreview = ({
	onImagesSelected,
	selectedImages,
	onImageRemove,
	onImageClick,
	selectedImageIndex,
	enableCropping,
	onFileObjectsChange,
}) => {
	const [error, setError] = useState("");
	const [cropModalOpen, setCropModalOpen] = useState(false);
	const [selectedForCrop, setSelectedForCrop] = useState(null);
	const [processedImages, setProcessedImages] = useState({});
	const [fileObjects, setFileObjects] = useState({});

	const { t: translate } = useTranslation();
	const fileInputRef = useRef(null);

	const handleImageSelect = async (event) => {
		const files = Array.from(event.target.files);
		const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB
		const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/webp"];

		setError("");
		setProcessedImages({});

		const invalidFiles = files.filter((file) => {
			if (file.size > MAX_FILE_SIZE) {
				setError(`File ${file.name} is too large. Maximum size is 1MB.`);
				return true;
			}
			if (!ALLOWED_TYPES.includes(file.type)) {
				setError(`File ${file.name} is not supported. Only JPG, PNG and WebP files are allowed.`);
				return true;
			}
			return false;
		});

		if (invalidFiles.length > 0) {
			return;
		}

		try {
			const compressedFiles = await Promise.all(
				files.map((file) => {
					return new Promise((resolve, reject) => {
						new Compressor(file, {
							quality: 0.8,
							maxWidth: 800,
							maxHeight: 800,
							success: (compressedFile) => {
								const compressedImageFile = new File([compressedFile], file.name, {
									type: compressedFile.type,
									lastModified: Date.now(),
								});
								const previewUrl = URL.createObjectURL(compressedImageFile);
								resolve({
									file: compressedImageFile,
									preview: previewUrl,
									name: file.name,
								});
							},
							error: (err) => {
								reject(err);
							},
						});
					});
				}),
			);

			// Store file objects locally
			const newFileObjects = compressedFiles.reduce(
				(acc, file, index) => ({
					...acc,
					[index]: file,
				}),
				{},
			);
			setFileObjects(newFileObjects);
			onFileObjectsChange(newFileObjects);

			// Pass only serializable data to parent
			onImagesSelected(
				compressedFiles.map((f) => ({
					name: f.name,
					preview: f.preview,
				})),
			);
		} catch (err) {
			setError("Error processing images. Please try again.");
			console.error("Error compressing images:", err);
		}
	};

	const handleImageRemove = (index) => {
		// Clean up local file object
		setFileObjects((prev) => {
			const newFileObjects = { ...prev };
			delete newFileObjects[index];
			return newFileObjects;
		});

		onImageRemove(index);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}

		setProcessedImages((prev) => {
			const newProcessedImages = { ...prev };
			delete newProcessedImages[index];
			return newProcessedImages;
		});
	};

	return (
		<Box sx={{ height: "100%" }}>
			<Box sx={{ mb: 4.5, display: "flex", justifyContent: "center" }}>
				<Button
					variant="contained"
					component="label"
					startIcon={<ImageIcon />}
					sx={{
						width: "100%",
						margin: "0 16px",
						color: "black",
						"&:hover": {
							opacity: 0.8,
						},
					}}
				>
					{translate("images.select")}
					<input
						ref={fileInputRef}
						type="file"
						hidden
						multiple
						accept="image/jpeg,image/png,image/webp"
						onChange={handleImageSelect}
					/>
				</Button>
			</Box>

			{error && (
				<Typography
					color="error"
					sx={{ mb: 2, textAlign: "center" }}
				>
					{error}
				</Typography>
			)}

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					maxHeight: "calc(100vh - 257px)",
					overflowY: "auto",
					px: 2,
				}}
			>
				{selectedImages.map((image, index) => (
					<Paper
						key={`${index}-${image.name}`}
						onClick={() => {
							if (enableCropping && !processedImages[index]) {
								setSelectedForCrop(image);
								setCropModalOpen(true);
							}
							onImageClick(index);
						}}
						sx={{
							p: 1,
							display: "flex",
							alignItems: "center",
							gap: 1,
							cursor: "pointer",
							border: selectedImageIndex === index ? "2px solid #F1801E" : "none",
							backgroundColor: selectedImageIndex === index ? "#171313" : "none",
							transition: "transform 0.2s ease",
							"&:hover": {
								transform: "scale(0.98)",
								backgroundColor: "#333130",
							},
							boxSizing: "border-box",
						}}
					>
						<Box
							component="img"
							src={image.preview}
							alt={image.name}
							sx={{
								width: 100,
								height: 100,
								objectFit: "cover",
								borderRadius: 1,
							}}
						/>
						<Typography
							sx={{
								flexGrow: 1,
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
						>
							{image.name}
						</Typography>
						<IconButton
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								handleImageRemove(index);
							}}
							sx={{ color: "error.main" }}
						>
							<DeleteIcon />
						</IconButton>
					</Paper>
				))}
			</Box>
			{cropModalOpen && selectedForCrop && (
				<ImageCropModal
					open={cropModalOpen}
					onClose={() => setCropModalOpen(false)}
					image={selectedForCrop}
					onCropComplete={(processedImage) => {
						setProcessedImages((prev) => ({
							...prev,
							[selectedImageIndex]: processedImage,
						}));

						// Update fileObjects with the new cropped file
						const newFileObjects = {
							...fileObjects,
							[selectedImageIndex]: processedImage,
						};
						setFileObjects(newFileObjects);
						onFileObjectsChange(newFileObjects);

						// Only pass serializable data to Redux
						const newImages = [...selectedImages];
						newImages[selectedImageIndex] = {
							name: processedImage.name,
							preview: processedImage.preview,
						};
						onImagesSelected(newImages);
					}}
				/>
			)}
		</Box>
	);
};

export default ProductImagePreview;
