import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './dashboardSlice';
import { mainApi } from '../api/mainApi';

export const store = configureStore({
	reducer: {
		dashboard: dashboardReducer,
		[mainApi.reducerPath]: mainApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(mainApi.middleware),
});
